import React, { useState } from "react";
import GeneralInformation from "../../components/GeneralInformation";
import PictureSlider from "../../components/PictureSlider/PictureSlider";
import { Text, useColorModeValue, IconButton } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import weather from "../../assets/images/lisbon/weather.png";
import "./destination.css"
import HowToGetThere from "../../components/HowToGetThere";
import CityTransports from "../../components/CityTransports/CityTransports";

function Destination() {
  const [show, setShow] = useState({
    language: false,
    climate: false,
    currency: false,
    visa: false,
    invitation: false,
    smoking: false,
    electricity: false,
    emergency: false,
    time: false,
    hours: false,
  });
  const {
    language,
    climate,
    currency,
    visa,
    invitation,
    smoking,
    electricity,
    emergency,
    time,
    hours,
  } = show;
  return (
    <div className="container">
      <PictureSlider />
      <GeneralInformation title="Lisbon">
        <Text fontSize="md" padding={2}>
          Spread out across a flurry of hills on the banks of the River Tagus,
          Lisbon is a city whose lifeblood has always been its wide river and
          the access to the Atlantic Ocean that it so readily provides. The city
          grew rich during the Age of the Discoveries in the fifteenth and
          sixteenth centuries, when intrepid Portuguese explorers set off down
          the Tagus and out on to the open ocean on their epic voyages to
          Africa, the Far East and Americas.
        </Text>
        <Text fontSize="md" padding={2}>
          Lisbon is a capital that very much wears its history on its sleeve
          with traces of the Phoenicians, Celts, Romans, and Visigoths and Moors
          all rumbling below the surface of a city that owes much of its current
          appearance to an eighteenth and nineteenth century makeover following
          an earthquake in 1755. The area follows a quadrangular plan and is
          called “Baixa”. On its streets graceful eighteenth century buildings
          complement rambling old districts like the Alfama and the Bairro Alto,
          while ornate Manueline styles provide a sharp contrast to the glass
          and steel modernity of the city’s newer developments.
        </Text>
        <Text fontSize="md" padding={2}>
          Lisbon today is a thoroughly modern metropolis as it demonstrated when
          it held the title of European City of Culture in 1994 and then staged
          the highly successful World Expo in 1998, an event that brought a
          revamped underground system, a new bridge across the Tagus (Vasco da
          Gama) and the rebirth of the waterfront area at the Park of Nations
          Expo site. In 2004 this dynamic city also hosted many of the European
          Championship football matches, including the final.
        </Text>
        <Text fontSize="md" padding={2}>
          Today Lisbon is not only at the heart of Portugal, but it also plays a
          central role as a gateway between North America and Europe. From the
          airport to the city centre off- peak takes only around 15 minutes.
          More direct airline routes than ever before, a high speed train link
          to Oporto and motorways to the north and to the Algarve mean that the
          Portuguese capital has never been better connected. These first-rate
          transport links have helped firmly cement its position as one of the
          world’s premier destinations. Lisbon is clearly a business hub on the
          move.
        </Text>
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, language: !language })}
          className="link"
        >
          Language
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !language ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {language && (
          <Text fontSize="md" padding={2}>
            The official languages of the event are Portuguese and English.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, climate: !climate })}
          className="link"
        >
          Climate and Clothing
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !climate ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {climate && (
          <>
            <Text fontSize="md" padding={2}>
              Portugal has a mild climate without extremes of temperature.
              Winters are never too cold and summers are always moderately hot.
              Lisbon climate is strongly influenced by the Gulf Stream and is
              one of the warmest European capitals. Spring and summer months are
              usually sunny.
            </Text>

            <Text fontSize="md" padding={2}>
              Average temperatures per month:.
            </Text>
            <img
              src={weather}
              alt="Average temperatures per month Lisbon"
              style={{ margin: "0 auto" }}
            />
          </>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, currency: !currency })}
          className="link"
        >
          Currency and Banking
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !currency ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {currency && (
          <Text fontSize="md" padding={2}>
            The unit of currency is the Euro (€). Some banks have automatic
            exchange machines. Reception in most hotels will change money and
            they are entitled to charge a small extra amount to cover
            fluctuation exchange rates. Traveller cheques and Eurocheques are
            accepted by all Portuguese banks and exchange bureaus. Visa, Amex,
            Eurocard, Diner Club and Master Card are the most useful credit
            cards.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, visa: !visa })}
          className="link"
        >
          Passport and Visa Information
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !visa ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {visa && (
          <Text fontSize="md" padding={2}>
            No visa requirements for EU passport holders. All non-EU citizens
            should contact their local embassies for specific requirements.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, invitation: !invitation })}
          className="link"
        >
          Official Invitation
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !invitation ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {invitation && (
          <Text fontSize="md" padding={2}>
            An official letter designed to assist in meeting administrative
            requirements in some countries (VISA), will be sent to any
            participant upon request. However, this invitation implies no
            obligation, financial or otherwise, by the Local Organising
            Committee.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, smoking: !smoking })}
          className="link"
        >
          Smoking Ban
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !smoking ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {smoking && (
          <Text fontSize="md" padding={2}>
            With effect from 1 January 2008, smoking is forbidden in almost all
            indoor public places, with the exception of some restaurants and
            bars that can designate 30 percent of their space for smokers if
            they are larger than 100 square metres. Smoking will not be
            permitted indoors in the meeting area. Non-compliance with this ban
            can lead to prosecution and a fine of up to 1.000,00 €uros.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, electricity: !electricity })}
          className="link"
        >
          Electricity
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !electricity ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {electricity && (
          <Text fontSize="md" padding={2}>
            The current is 220 volts AC, with a continental round pin plug.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, emergency: !emergency })}
          className="link"
        >
          Emergency Phone Numbers
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !emergency ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {emergency && (
          <Text fontSize="md" padding={2}>
            For Police and Ambulance emergency dial 112.
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, time: !time })}
          className="link"
        >
          Time Zone
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !time ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {time && (
          <Text fontSize="md" padding={2}>
            GMT (Greenwich Mean Time)
          </Text>
        )}
        <Text
          fontSize={{ base: "16px", lg: "18px" }}
          color={useColorModeValue("yellow.500", "yellow.300")}
          fontWeight={"500"}
          mt={"4"}
          padding={2}
          onClick={() => setShow({ ...show, hours: !hours })}
          className="link"
        >
          Opening Hours
          <IconButton
            aria-label="Show"
            variant="none"
            icon={
              !hours ? (
                <ChevronDownIcon boxSize={8} />
              ) : (
                <ChevronUpIcon boxSize={8} />
              )
            }
          />
        </Text>
        {hours && (
          <Text fontSize="md" padding={2}>
            Banks: Are open from 08:30 a.m. to 03:00 p.m. Monday to Friday,
            closing on public holidays. Shops: From 09:00 a.m. to 01:00 p.m. and
            03:00 p.m. to 07:00 p.m. (working days). On Saturdays most shops
            close at 01:00 p.m., even though there are some open in the
            afternoon. Shopping Centres: are open from 10:00 a.m. to 11:00 p.m.
            or midnight, every day of the week.
          </Text>
        )}
      </GeneralInformation>
      <HowToGetThere />
      <CityTransports />
    </div>
  );
}

export default Destination;
