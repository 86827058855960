import React from "react";
import { Heading, Flex } from "@chakra-ui/react";

function Gallery() {
  return (
    <>
      <Flex py={16} justify={"center"} align={"center"} className="container">
        <Heading lineHeight={1.1} as="h3" size="lg">
          Coming Soon
        </Heading>
      </Flex>
    </>
  );
}

export default Gallery;
