import sponsor1 from "../../assets/images/sponsors/clarins.png";
import sponsor2 from "../../assets/images/sponsors/cml.png";
import sponsor3 from "../../assets/images/sponsors/eur2021.png";
import sponsor4 from "../../assets/images/sponsors/gcp.png";
import sponsor5 from "../../assets/images/sponsors/lg.png";
import partner1 from "../../assets/images/partners/agl.png";
import partner2 from "../../assets/images/partners/fgp.png";
import partner3 from "../../assets/images/partners/scs.png";

export const sponsors = [sponsor1, sponsor2, sponsor3, sponsor4, sponsor5];

export const partners = [partner1, partner2, partner3];
