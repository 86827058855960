import React from "react";
import Pricing from "../components/Pricing";
import GeneralInformation from "../components/GeneralInformation";
import { Text } from "@chakra-ui/react";

function Accommodation() {
  return (
    <div className="container">
      <GeneralInformation title="General Information">
        <Text fontSize="md" padding={2}>
          A number of rooms have been reserved for Teamgym International Open
          for Clubs and Micro-Team International Open for Clubs 2020 in some
          hotels close to the venue. We must stress that as we are not a travel
          agency and all our hotel selection is based on objective analysis of
          the properties and on a quality-cost basis.
        </Text>
        <Text fontSize="md" padding={2}>
          The chosen hotels are of a very high standard at excellent discounted
          rates.
        </Text>
        <Text fontSize="md" padding={2}>
          Prices are in Euro (€) and include VAT and breakfast (except where
          otherwise specified). All rooms have private bath or shower.
        </Text>
        <Text fontSize="md" padding={2}>
          To ensure that you get your preferred accommodation, we advise you to
          book your room(s) as soon as possible.
        </Text>

        <Text fontSize="md" padding={2}>
          <Text as={"span"} fontWeight={"bold"}>
            Note:
          </Text>{" "}
          Rates per Night/ Person include Full Board Accommodation with
          breakfast, lunch, dinner and transfers.
        </Text>
      </GeneralInformation>
      <Pricing />
    </div>
  );
}

export default Accommodation;
