import React from 'react'
import ContactForm from "../components/ContactForm/ContactForm"

function Contacts() {
  return (
    <div className="container">
      <ContactForm />
    </div>
  )
}

export default Contacts