import React from "react";
import CallForSponsors from "../components/CallForSponsors";
import Sponsors from "../components/Sponsors/Sponsors";
import SponsorshipCards from "../components/SponsorshipCards";

const data = [
  {
    id: "exhibition",
    image:
      "https://images.unsplash.com/photo-1583155778358-9da4eb5e0c1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1172&q=80",
    title: "Sponsorship with Exhibition Area",
    text: "At your request the event secretariat will provide a copy of the call for sponsors. There are a variety of sponsorship categories available. For each category there are a limited number of sponsors, so we encourage you to complete your application earlier so we may guarantee your preference.",
  },
  {
    id: "kit",
    image:
      "https://images.unsplash.com/photo-1573858129038-6f98c3cb2ac7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80",
    title: "Sponsorship Kit",
    text: "There are many possibilities to raise your company profile before, during and after the Teamgym International Open for Clubs and Micro-Team International Open for Clubs 2018.",
  },
];

function Sponsorships() {
  return (
    <div className="container">
      <CallForSponsors />
      <Sponsors />
      {data.map((e) => {
        return (
          <div key={e.id} id={e.id}>
            <SponsorshipCards image={e.image} title={e.title} text={e.text} />
          </div>
        );
      })}
    </div>
  );
}

export default Sponsorships;
