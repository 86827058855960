import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  List,
  ListItem,
} from "@chakra-ui/react";

function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderColor={useColorModeValue("gray.200", "gray.500")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export default function ThreeTierPricing() {
  return (
    <Box py={16} bg={"gray.600"} color={"white"}>
      <VStack spacing={2} textAlign="center" maxW={"4xl"} margin="0 auto">
        <Heading lineHeight={1.1} as="h3" size="lg">
          Book Your Accommodation
        </Heading>
        <Text fontSize="lg">
          All reservations will be made on the basis of “first come, first
          served”, according to the available number of rooms in each hotel.
          Other options are also available on request.
        </Text>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Pack A
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                4 Stars
              </Text>
              <Text fontSize="3xl" color="gray.500">
                category
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12} color={"gray.500"}>
              <ListItem>
                Single room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  90 euros
                </Text>
              </ListItem>
              <ListItem>
                Double room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  75 euros
                </Text>
              </ListItem>
              <ListItem>
                Triple room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  65 euros
                </Text>
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Pack B
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                3 Stars
              </Text>
              <Text fontSize="3xl" color="gray.500">
                category
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12} color={"gray.500"}>
              <ListItem>
                Single room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  85 euros
                </Text>
              </ListItem>
              <ListItem>
                Double room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  70 euros
                </Text>
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Pack C
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="5xl" fontWeight="900">
                Hostel
              </Text>
              <Text fontSize="3xl" color="gray.500">
                low cost
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue("gray.50", "gray.700")}
            py={4}
            borderBottomRadius={"xl"}
          >
            <List spacing={3} textAlign="start" px={12} color={"gray.500"}>
              <ListItem>
                Shared room:{" "}
                <Text as={"span"} fontWeight={"bold"}>
                  50 euros
                </Text>
              </ListItem>
            </List>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  );
}
