import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
} from "@chakra-ui/react";

export default function CallToActionWithIllustration() {
  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={20}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          Join as a{" "}
          <Text as={"span"} color={"orange.400"}>
            sponsor
          </Text>
        </Heading>
        <Text color={"gray.500"} maxW={"3xl"}>
          Sponsorship opportunities are available at various levels, including a
          limited number of Major Sponsor opportunities. Major Sponsors will be
          offered the highest profile at the event. Please contact the event
          secretariat to discuss how your organization can benefit from
          sponsoring an event at the Teamgym International Open for Clubs and
          Micro-Team International Open for Clubs 2018.
        </Text>
        <Stack spacing={6} direction={"row"}>
          <a href="#exhibition">
            <Button
              rounded={"full"}
              px={6}
              colorScheme={"orange"}
              bg={"orange.400"}
              _hover={{ bg: "orange.500" }}
            >
              Sponsorship with Exhibition Area
            </Button>
          </a>
          <a href="#kit">
            <Button rounded={"full"} px={6}>
              Sponsorship Kit
            </Button>
          </a>
        </Stack>
        <Flex w={"full"}></Flex>
      </Stack>
    </Container>
  );
}
