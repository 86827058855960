import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
  Center,
  Heading
} from "@chakra-ui/react";

function AgesCategories() {
  return (
    <Box py={16} bg={"gray.600"} color={"white"}>
      <Center display={"flex"} flexDirection={"column"}>
        <Heading lineHeight={1.1} as="h3" size="lg">
          Ages Category Definition{" "}
        </Heading>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Category</Th>
                <Th>Age Gap</Th>
                <Th>Year of birth</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Mini</Td>
                <Td>8 to 11 years old</Td>
                <Td>2012 - 2009</Td>
              </Tr>
              <Tr>
                <Td>Youth</Td>
                <Td>10 to 14 years old</Td>
                <Td>2010 - 2006</Td>
              </Tr>
              <Tr>
                <Td>Junior</Td>
                <Td>13 to 17 years old</Td>
                <Td>2007 - 2003</Td>
              </Tr>
              <Tr>
                <Td>Senior</Td>
                <Td>16 years old and above</Td>
                <Td>≤ 2004</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Center>
    </Box>
  );
}

export default AgesCategories;
