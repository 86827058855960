import {
  Box,
  Divider,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

const teamgym = [
  { id: 1, desc: "Floor programme" },
  { id: 2, desc: "Tumbling (Airtrack for non-elite sections / Official TeamGym tumbling track for elite sections)" },
  { id: 3, desc: "Trampet" },
];
const microTeam = [
    { id: 1, desc: "Tumbling (Airtrack for all sections)" },
    { id: 2, desc: "Trampet" },
  ];

const PackageTier = ({ title, options }) => {
  return (
    <Stack
      p={3}
      py={3}
      justifyContent={{
        base: "flex-start",
        md: "space-around",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
      alignItems={{ md: "center" }}
    >
      <Heading size={"md"}>{title}</Heading>
      <List spacing={3} textAlign="start" width={"30%"}>
        {options.map((desc, id) => (
          <ListItem key={desc.id}>
            <ListIcon as={FaCheckCircle} color="green.500" />
            {desc.desc}
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
const ThreeTierPricingHorizontal = () => {
  return (
    <Box py={16} maxWidth={"4xl"} margin={"0 auto"}>
      <Stack spacing={4} width={"100%"} direction={"column"}>
        <Stack
          p={5}
          alignItems={"center"}
          justifyContent={{
            base: "flex-start",
            md: "space-around",
          }}
          direction={{
            base: "column",
            md: "column",
          }}
        >
          <Stack
            width={{
              base: "100%",
              md: "100%",
            }}
            textAlign={"center"}
          >
            <Heading as="h3" size="lg">
              Competition Programme
            </Heading>
          </Stack>
          <Stack
            width={{
              base: "100%",
              md: "60%",
            }}
          >
            <Text textAlign={"center"}>
              The following disciplines are compulsory for every participating
              team
            </Text>
          </Stack>
        </Stack>
        <Divider />
        <PackageTier
          title={"Teamgym International Open For Clubs"}
          options={teamgym}
        />
        <Text textAlign={"center"} fontSize='sm'>All gymnasts participating in the competition must perform on floor programme.</Text>
        <Divider />
        <PackageTier
          title={"Micro-Team International Open For Clubs"}
          options={microTeam}
        />
        <Divider />
      </Stack>
    </Box>
  );
};

export default ThreeTierPricingHorizontal;
