import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Center,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";

const features = [
  {
    id: 1,
    title: "Provisional entry",
    text: "Until the 12th of February (International Teams Only)",
  },
  {
    id: 2,
    title: "Visa request",
    text: "Until the 12th of February (International Teams Only)",
  },
  {
    id: 3,
    title: "Accomodation request",
    text: "Until the 12th of March (International and Portuguese Teams)",
  },
  {
    id: 4,
    title: "Definitive entry",
    text: "Until the 12th of March (International and Portuguese Teams)",
  },
  {
    id: 5,
    title: "Nominative entry",
    text: "Until the 12th of April (International and Portuguese Teams)",
  },
  {
    id: 6,
    title: "Travel details",
    text: "Until the 12th of April (International Teams Only)",
  },
];

export default function Deadlines() {
  return (
    <Box py={16} bg={"gray.600"} color={"white"}>
      <Center>
        <Heading as="h3" size="lg" pb={10}>
          Registration Deadlines
        </Heading>
      </Center>
      <Container maxW={"6xl"} mt={2}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={"top"}>
              <Box color={"green.400"} px={2}>
                <Icon as={TimeIcon} />
              </Box>
              <VStack align={"start"}>
                <Text fontWeight={600}>{feature.title}</Text>
                <Text >{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}
