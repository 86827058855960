import React from "react";
import Event from "../components/Event";
import {
  Container,
  Heading,
  VStack,
  StackDivider,
} from "@chakra-ui/react";

function Programme() {
  return (
    <Container py={16} className="container">
      <Heading pb={10}>Preliminary Programme</Heading>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={4}
        align="stretch"
      >
        {DAYS.map((day) => {
          return <Event day={day} />;
        })}
      </VStack>
    </Container>
  );
}

export default Programme;

const DAYS = [
  {
    date: "Thursday – 7th of May",
    events: [
      "Early International delegations arrival",
      "Podium Training (according to schedule)",
    ],
  },
  {
    date: "Friday – 8th of May",
    events: [
      "International delegations arrival",
      "Podium Training (according to schedule)",
    ],
  },
  {
    date: "Saturday – 9th of May",
    events: ["Teamgym International Open For Clubs (day 1)", "Award Ceremony"],
  },
  {
    date: "Sunday – 10th of May",
    events: [
      "Micro-Team Competition (day 2)",
      "Award Ceremony",
      "Final Dinner and Farewell Party",
    ],
  },
  {
    date: "Monday – 11th of May",
    events: ["Departure of all delegations"],
  },
];
