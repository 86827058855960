import React from "react";
import { Heading, Text } from "@chakra-ui/react";

function Event({ day }) {
  const { date, events } = day;
  return (
    <>
      <Heading as="h4" size="md">
        {date}
      </Heading>
      {events.map((event) => {
        return <Text fontSize="md">{event}</Text>;
      })}
    </>
  );
}

export default Event;
