import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { IoIosTrain, IoIosAirplane, IoIosBoat, IoIosCar } from "react-icons/io";

const Feature = ({ title, text, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box py={16} bg={"gray.600"} color={"white"}>
      <Flex align={"center"} justify={"center"} color={"white"} mb={12}>
        <Heading lineHeight={1.1} as="h3" size="lg">
          How to Get There
        </Heading>
      </Flex>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={10}
        maxW={"7xl"}
        margin={"0 auto"}
      >
        <Feature
          icon={<Icon as={IoIosAirplane} color="blue.300" w={10} h={10} />}
          title={"By air"}
          text={
            "Lisbon International Airport, 7 km from the city centre, has daily flights to and from the major cities in Europe and the world. Right now, 71 airlines operate out of Lisbon Airport. Lisbon Airport offers nonstop flights to 121 cities. Every week, at least 728 domestic flights and 3,451 international flights depart from Lisbon Airport."
          }
        />
        <Feature
          icon={<Icon as={IoIosTrain} color="blue.300" w={10} h={10} />}
          title={"By rail"}
          text={
            "Scores of national and international trains arrive in Lisbon every day. In addition to Santa Apolónia terminal station, the city now has the new Gare do Oriente, which opened in 1998 adjacent to the Parque das Nações. Both stations have direct bus or underground connections to the city centre."
          }
        />
        <Feature
          icon={<Icon as={IoIosBoat} color="blue.300" w={10} h={10} />}
          title={"By sea"}
          text={
            "The Port of Lisbon is the busiest port on the European Atlantic coast. It has three terminals for cruise ships: the Alcântara, Rocha de Conde d’Óbidos and the Santa Apolónia terminals. Lisbon is often a port of call for many cruise ships (coming from many different places). Furthermore, the city also has marinas for pleasure boats in the docks of Belém, Santo Amaro, Bom Sucesso. Alcântara and, most recently, the Olivais Docks."
          }
        />
        <Feature
          icon={<Icon as={IoIosCar} color="blue.300" w={10} h={10} />}
          title={"By road"}
          text={
            "Arriving in Lisbon by road is a pleasant experience, as the visitor can enjoy the beautiful countryside along the way. The city has good road accesses and the most frequently used routes are: the A1 highway (E90) entering from the north or A2/ A6 highway and then the 25th April Bridge or Vasco da Gama Bridge entering from the south for the Lisbon region."
          }
        />
      </SimpleGrid>
    </Box>
  );
}
