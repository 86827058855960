import { Avatar, Box, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import president from "../assets/images/manuel_cavaleiro.jpg";

export default function WelcomeLetter() {
  return (
    <Stack
      bg={useColorModeValue("gray.50", "gray.800")}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={"center"}
      direction={"column"}
      className="container"
    >
      <Text
        fontSize={{ base: "sm", md: "md" }}
        textAlign={"justify"}
        maxW={"5xl"}
      >
        Dear Gymnastic Friends!
        <br />
        <br />
        It is my great pleasure to Welcome You once again for the 2020 Edition
        of the GCP LISBON TEAMGYM & MICRO – TEAM INTERNATIONAL OPEN FOR CLUBS.
        <br />
        Lisbon is welcoming the best teams and gymnast from Teamgym clubs and it
        is a great honour and a significant event for us.
        <br />
        Our country and Club particularly has accumulated great experience in
        hosting large international events, and we have no doubt that Lisbon
        will meet once again, if not exceed, the expectations of all the
        participants and guests of the this Teamgym Competitions.
        <br />I want to assure you that we will do everything to make Lisbon a
        hospitable home for all the participants, where you can not only test
        your teams on an international event, but also interact with like-minded
        people and make new friends.
        <br />I am sure every participant will enjoy the Lisbon Region and will
        have the opportunity to have a look at the infinite variety of things
        the city has to offer.
        <br />
        I’m confident that the up-coming Lisbon Teamgym & Micro-team
        International Open for Clubs will bestow upon your lasting impressions.
        <br />
        From me personally and on behalf of our Club the Ginásio Clube
        Português, we wish all the participants high jumps, healthy competition
        and sporting achievements!
        <br />
        We look forward to welcoming you again or for the first time in Lisbon
        in 2020.
        <br />
        <br />
        Best Wishes,
      </Text>
      <Box textAlign={"center"}>
        <Avatar src={president} alt={"Manuel Cavaleiro Ferreira"} mb={2} />

        <Text fontWeight={600}>Manuel Cavaleiro Ferreira</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.400", "gray.400")}>
          President of Ginásio Clube Português and
          <br />
          Chairperson of Lisbon Teamgym & Micro-Team International Open for
          Clubs 2020
        </Text>
      </Box>
    </Stack>
  );
}
