import React from "react";
import { Box, List, ListItem, ListIcon, Center, Link } from "@chakra-ui/react";
import { MdLink } from "react-icons/md";

export default function RulesDownloads() {
  return (
    <Box py={16} bg={"gray.600"} color={"white"}>
      <Center>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={MdLink} color="green.500" />
            <Link href="https://www.europeangymnastics.com/" isExternal>
              Guidelines
            </Link>
          </ListItem>
          <ListItem>
            <ListIcon as={MdLink} color="green.500" />
            <Link href="https://www.europeangymnastics.com/" isExternal>
              Workplan
            </Link>
          </ListItem>
          <ListItem>
            <ListIcon as={MdLink} color="green.500" />
            <Link href="https://www.europeangymnastics.com/" isExternal>
              UEG Code of Points{" "}
            </Link>
          </ListItem>
        </List>
      </Center>
    </Box>
  );
}
