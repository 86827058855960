import React from "react";
import AgesCategories from "../components/AgesCategories";
import CompetitionFormat from "../components/CompetitionFormat";
import CompetitionProgramme from "../components/CompetitionProgramme";
import RulesDownloads from "../components/RulesDownloads";

function Rules() {
  return (
    <div className="container">
      <CompetitionFormat />
      <AgesCategories />
      <CompetitionProgramme />
      <RulesDownloads />
    </div>
  );
}

export default Rules;
