import {
  Box,
  Container,
  Stack,
  Text,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  Icon,
  List,
  ListItem,
  Center
} from "@chakra-ui/react";
import { MdMonetizationOn } from "react-icons/md";

export default function Simple() {
  return (
    <Container maxW={"3xl"}>
      <SimpleGrid
        columns={{ base: 1, lg: 1 }}
        spacing={{ base: 8, md: 10 }}
        py={16}
      >
        <Stack spacing={{ base: 6, md: 10 }}>
          <Center>
            <Heading lineHeight={1.1} as="h3" size="lg">
              Competition Fees
            </Heading>
          </Center>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={"column"}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.200", "gray.600")}
              />
            }
          >
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                mb={"4"}
              >
                Fees
              </Text>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={1}>
                  <ListItem>Teamgym International Open for Clubs</ListItem>
                  <ListItem
                    fontWeight={"600"}
                    display="flex"
                    alignItems={"center"}
                  >
                    <Icon as={MdMonetizationOn} mx={2} />
                    <Text>75 euros per team (same club)</Text>
                  </ListItem>
                </List>
                <List spacing={1}>
                  <ListItem>Micro-Team International Open for Clubs</ListItem>
                  <ListItem
                    fontWeight={"600"}
                    display="flex"
                    alignItems={"center"}
                  >
                    <Icon as={MdMonetizationOn} mx={2} />
                    <Text>25 euros per micro-team (same club)</Text>
                  </ListItem>
                </List>
              </SimpleGrid>
            </Box>
            <Box spacing={{ base: 4, sm: 6 }}>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                mb={"4"}
              >
                Notes
              </Text>
              <Text fontSize={"md"} mb={4}>
                If your team do not present at least one judge there will be a
                penalty of 75 euros per competition (Day 1 - Teamgym / Day 2 -
                Microteam).
              </Text>
              <Box spacing={{ base: 4, sm: 6 }} mb={4}>
                <Text as={"span"} fontWeight={"bold"}>
                  Payments:{" "}
                </Text>
                <Text fontSize={"md"} as={"span"}>
                  The payment of the Competition Fee (100%) must be made by the
                  clubs / teams to the Organising Committee by 12th of March
                  2019 (Definitive Entry). No payment = no accreditation for
                  competition.
                </Text>
              </Box>
              <Box spacing={{ base: 4, sm: 6 }}>
                <Text as={"span"} fontWeight={"bold"}>
                  Accommodation and Meals:{" "}
                </Text>
                <Text fontSize={"md"} as={"span"}>
                  A 50% pre-payment (€) must be made by the Clubs/teams to the
                  Organizing Committee by, 12th of March 2019 (Definitive
                  entry). The remaining payment of 50% must be made by the
                  clubs/teams to the Organizing Committee by, 12th of April
                  2019. No payment = no hotel reservation and no meals.
                </Text>
              </Box>
            </Box>
            <Box>
              <Text
                fontSize={{ base: "16px", lg: "18px" }}
                color={useColorModeValue("yellow.500", "yellow.300")}
                fontWeight={"500"}
                mb={"4"}
              >
                Bank Account Details
              </Text>
              <List spacing={2}>
                <ListItem>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      Beneficiary:{" "}
                    </Text>
                    <Text as={"span"}>Ginásio Clube Português</Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      Address:{" "}
                    </Text>
                    <Text as={"span"}>
                      Praça do Ginásio Clube Português, nº 1, 1250-111 Lisboa
                    </Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      VAT Number:{" "}
                    </Text>
                    <Text as={"span"}>500127174</Text>
                  </Box>
                </ListItem>
                <ListItem>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      Bank Name:{" "}
                    </Text>
                    <Text as={"span"}>Banco Português de Investimento</Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      Address:{" "}
                    </Text>
                    <Text as={"span"}>
                      Rua Artilharia Um, n.º 51, 1250-038 Lisboa
                    </Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      IBAN:{" "}
                    </Text>
                    <Text as={"span"}>PT50 0010 0000 2660 2950 0025 3</Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      NIB:{" "}
                    </Text>
                    <Text as={"span"}>0010 0000 26602950002 53</Text>
                  </Box>
                  <Box>
                    <Text as={"span"} fontWeight={"bold"}>
                      SWIFT:{" "}
                    </Text>
                    <Text as={"span"}>BBPIPTPL</Text>
                  </Box>
                </ListItem>
              </List>
            </Box>
          </Stack>{" "}
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
