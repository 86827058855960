import React from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import { sponsors, partners } from "./index";
import "./sponsors.css"

function Sponsors() {
  return (
    <Box width={"4xl"} margin={"0 auto"} mb={16}>
      <Flex direction={"column"}>
        <Heading as="h3" size="lg" mb={6}>
          Sponsors
        </Heading>
        <Flex
          align={"center"}
          justify={"space-evenly"}
          wrap={"wrap"}
        >
          {sponsors.map((e) => {
            return <img src={e} alt="sponsor" className="img"/>;
          })}
        </Flex>
      </Flex>
      <Flex direction={"column"}>
        <Heading as="h3" size="lg" mb={6}>
          Partners
        </Heading>
        <Flex
          align={"center"}
          justify={"space-evenly"}
          wrap={"wrap"}
        >
          {partners.map((e) => {
            return <img src={e} alt="partner" className="img"/>;
          })}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Sponsors;
