import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Heading,
} from "@chakra-ui/react";
import {
  MdOutlineTram,
  MdOutlineDirectionsBusFilled,
  MdOutlineSubway,
  MdOutlineDirectionsCarFilled,
} from "react-icons/md";
import "./cityTransports.css";

const Feature = ({ title, children, icon }) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      {children}
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box py={16}>
      <Flex align={"center"} justify={"center"} mb={12}>
        <Heading lineHeight={1.1} as="h3" size="lg">
          Lisbon City Transports
        </Heading>
      </Flex>
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={10}
        maxW={"7xl"}
        margin={"0 auto"}
      >
        <Feature
          icon={
            <Icon
              as={MdOutlineDirectionsBusFilled}
              color="blue.300"
              w={10}
              h={10}
            />
          }
          title={"Bus"}
        >
          <Text>
            Linking the Airport to downtown Lisboa and Cais do Sodré (every 20
            minutes from 7 a.m. to 9 p.m.)
          </Text>
          <Text>
            <a
              href="https://www.carris.pt/"
              target={"_blank"}
              rel="noreferrer"
              className="link"
            >
              Carris
            </a>{" "}
            has the exclusive rights as the above ground mass public transport
            operator in Lisbon. The network is made up of 88 routes: 8 late
            night / early morning (from 11.45 p.m. to 5.30 a.m.); The network
            covers more or less 600 kms in the city of Lisbon; Buses from the
            airport to the town centre are n.º 5, 8, 22, 44, 45, 83.
          </Text>
        </Feature>
        <Feature
          icon={<Icon as={MdOutlineTram} color="blue.300" w={10} h={10} />}
          title={"Tram"}
        >
          <Text>
            The tram network is made up of 5 routes, 3 funiculars (Glória, Bica
            and Lavra) and 1 lift (Santa Justa). The tram network covers 48kms
            in the city of Lisbon. The most touristic route is served by the
            number 28.
          </Text>
        </Feature>
        <Feature
          icon={<Icon as={MdOutlineSubway} color="blue.300" w={10} h={10} />}
          title={"Subway"}
        >
          <Text>
            The Lisbon underground{" "}
            <a
              href="www.metrolisboa.pt"
              target={"_blank"}
              rel="noreferrer"
              className="link"
            >
              Metro Lisboa
            </a>{" "}
            has four major routes: Linha Azul (blue line), Linha Amarela (yellow
            line), Linha Vermelha (red line) – VENUE; HOTEL and AIRPORT LANE,
            Linha Verde (green line).
          </Text>
          <Text>
            The first train leaves at 06:30 from the terminal stations of each
            line. The last train leaves at 01:00 from the terminal stations of
            each line.
          </Text>
        </Feature>
        <Feature
          icon={
            <Icon
              as={MdOutlineDirectionsCarFilled}
              color="blue.300"
              w={10}
              h={10}
            />
          }
          title={"Taxi"}
        >
          <Text>Available right outside the Hotel.</Text>
        </Feature>
      </SimpleGrid>
    </Box>
  );
}
