import logo from "../../assets/images/gcp.png";
import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  chakra,
  VisuallyHidden,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import "./footer.css"

const Logo = () => {
  return <img src={logo} alt="Ginasio Clube Portugues" width={150} />;
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg="rgba(99, 179, 237, 0.3)"
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr", md: "2fr 1fr 1fr 1fr 1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6} className="footer-container">
            <Box>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>
            <Text fontSize={"sm"}>© 2022 Ginásio Clube Português</Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton label={"Twitter"} href={"#"}>
                <FaTwitter />
              </SocialButton>
              <SocialButton label={"YouTube"} href={"#"}>
                <FaYoutube />
              </SocialButton>
              <SocialButton label={"Instagram"} href={"#"}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack className="footer-container">
            <ListHeader>About</ListHeader>
            <Link href={"/welcome-letter"}>Welcome Letter</Link>
            <Link href={"/contacts"}>Contacts</Link>
          </Stack>
          <Stack className="footer-container">
            <ListHeader>Event</ListHeader>
            <Link href={"/programme"}>Programme</Link>
            <Link href={"/registration"}>Registration</Link>
            <Link href={"/rules"}>Rules</Link>
          </Stack>
          <Stack className="footer-container">
            <ListHeader>Hospitality</ListHeader>
            <Link href={"/accommodation"}>Accommodation</Link>
            <Link href={"/destination"}>Destination</Link>
          </Stack>
          <Stack className="footer-container">
            <ListHeader>Partners</ListHeader>
            <Link href={"/sponsorships"}>Sponsorships</Link>
          </Stack>
          <Stack className="footer-container">
            <ListHeader>
              <Link href={"/gallery"}>Gallery</Link>
            </ListHeader>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
