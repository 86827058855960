import Banner from "./components/Banner";
import WithSubnavigation from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Homepage from "./view/Homepage";
import Contacts from "./view/Contacts";
import Programme from "./view/Programme";
import Registration from "./view/Registration";
import Rules from "./view/Rules";
import Accommodation from "./view/Accommodation";
import Destination from "./view/Destination/Destination";
import Sponsorships from "./view/Sponsorships";
import Gallery from "./view/Gallery";
import WelcomeLetter from "./view/WelcomeLetter";
import "./app.css"

function App() {
  return (
    <div className="App">
      <Banner />
      <WithSubnavigation />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/welcome-letter" element={<WelcomeLetter />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/programme" element={<Programme />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/accommodation" element={<Accommodation />} />
        <Route path="/destination" element={<Destination />} />
        <Route path="/sponsorships" element={<Sponsorships />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
