import React from "react";
import Deadlines from "../components/Deadlines";
import GeneralInformation from "../components/GeneralInformation";
import Fees from "../components/Fees";
import { Text } from "@chakra-ui/react";

function Registration() {
  return (
    <div className="container">
      <GeneralInformation title="General Information">
        <Text fontSize="md" padding={2}>
          All clubs / teams should register on-line in our registration
          platform.
        </Text>
        <Text fontSize="md" padding={2}>
          <a href="#" className="link">
            Click here
          </a>{" "}
          to access the registration form.
        </Text>
        <Text fontSize="md" padding={2}>
          After registering you can access your account in the Member's Area and
          start managing your information.
        </Text>
        <Text fontSize="md" padding={2}>
          It is not possible to register during the event.
        </Text>
        <Text fontSize="md" padding={2}>
          For greater efficiency and expedience, on-line registration is highly
          recommended.
        </Text>
        <Text fontSize="md" padding={2}>
          The organization reserves the right to alter the venue if necessary.
        </Text>
        <Text fontSize="md" padding={2}>
          Major Force Events: If the event is cancelled the organisers shall not
          be responsible for airfare, hotel or other costs incurred by the
          participants. Insurance on this matter is recommended.
        </Text>
      </GeneralInformation>
      <Deadlines />
      <Fees />
    </div>
  );
}

export default Registration;
