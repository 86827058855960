import React from "react";
import { Container, Heading, Center } from "@chakra-ui/react";

function GeneralInformation({title, children}) {
  return (
    <Container py={16} maxW="3xl">
      <Center>
        <Heading as="h3" size="lg" pb={10}>
          {title}
        </Heading>
      </Center>
      {children}
    </Container>
  );
}

export default GeneralInformation;
